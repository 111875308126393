import {Routes} from '@angular/router';

import {FullComponent} from './layouts/full/full.component';
import {StarterComponent} from "./starter/starter.component";
import {LoginComponent} from "./auth/login/login.component";
import {LogoutComponent} from "./auth/logout/logout.component";
import {AuthGuard} from "./guard/auth.guard";
import {DashboardComponent} from "./material-component/dashboard/dashboard.component";

export const AppRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: './material-component/material.module#MaterialComponentsModule'
            },
            {
                path: 'starter',
                loadChildren: './starter/starter.module#StarterModule'
            }
        ]
    }

];

