import {Injectable} from '@angular/core';

export interface Menu {
    name: string;
    type: string;
    icon: string;
    state?: string;
    children?: any;
}

const MENUITEMS = [
    {
        name: 'Dashboard',
        type: 'link',
        icon: 'supervisor_account',
        state: 'dashboard'
    },
    {
        name: 'Students',
        type: 'title',
        icon: 'supervisor_account',
        children: [
            {state: 'customer', name: 'Add New', type: 'link', icon: 'person_add'},
            {state: 'customer-list', name: 'Search', type: 'link', icon: 'youtube_searched_for'},

        ]
    },
    {
        name: 'Staff',
        type: 'title',
        icon: 'recent_actors',
        children: [
            {state: 'staff', name: 'Add New', type: 'link', icon: 'add_to_photos'},
            {state: 'staff-list', name: 'Search', type: 'link', icon: 'youtube_searched_for'},

        ]
    },
    {
        name: 'Contact',
        type: 'link',
        icon: 'contacts',
        children: [
            {state: 'contact', name: 'Contact', type: 'link', icon: 'add_to_photos'},
            {state: 'personal_loan', name: 'Personal Loan', type: 'link', icon: 'attach_money'},

        ]
    },
    {
        name: 'Reminders',
        type: 'link',
        icon: 'notifications',
        children: [
            {state: 'reminder', name: 'Reminder', type: 'link', icon: 'add_alert'},

        ]
    },
    {
        name: 'Receipts',
        type: 'title',
        icon: 'receipt',
        children: [
            {state: 'receipt', name: 'Add New', type: 'link', icon: 'playlist_add'},
            {state: 'receipt-list', name: 'Search', type: 'link', icon: 'youtube_searched_for'},

        ]
    },
    {
        name: 'Vouchers',
        type: 'title',
        icon: 'assignment',
        children: [
            {state: 'voucher', name: 'Add New', type: 'link', icon: 'playlist_add'},
            {state: 'voucher-list', name: 'Search', type: 'link', icon: 'youtube_searched_for'},

        ]
    },
    {
        name: 'Reports',
        type: 'title',
        icon: 'menu',
        children: [
            {state: 'day-book', name: 'Day Book', type: 'link', icon: 'description'},
            {state: 'ledger', name: 'Ledger', type: 'link', icon: 'account_balance_wallet'},
            {state: 'balance_sheet', name: 'Balance Sheet', type: 'link', icon: 'insert_chart'},
            {state: 'profit_and_loss', name: 'Profit & Loss', type: 'link', icon: 'confirmation_number'},

        ]
    },
    {
        name: 'Store Room',
        type: 'title',
        icon: 'receipt',
        children: [
            {state: 'store-item', name: 'Store Item', type: 'link', icon: 'view_comfy'},
            {state: 'store-room', name: 'Store Room', type: 'link', icon: 'filter_frames'},
        ]
    },
    {
        name: 'Settings',
        type: 'title',
        icon: 'settings',
        children: [
            {state: 'hostel', name: 'Hostels', type: 'link', icon: 'view_comfy'},
            {state: 'accounts-head-list', name: 'Accounts Head', type: 'link', icon: 'settings_input_composite'},
            {state: 'financial-year', name: 'Financial Year', type: 'link', icon: 'filter_frames'},
            {state: 'password_change', name: 'Password change', type: 'link', icon: 'security'},
            {state: 'logout', name: 'Log Out', type: 'link', icon: 'settings_power'},
        ]
    },


];

@Injectable()

export class MenuItems {
    getMenuitem(): Menu[] {
        return MENUITEMS;
    }

}
