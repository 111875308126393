import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {AutoCompleteData, serverResponseData} from '../model/common';
import {MatDialog} from '@angular/material';
import {NotificationsComponent} from '../material-component/notifications/notifications.component';
import {Observable} from 'rxjs/Rx';
import 'rxjs-compat/add/operator/map';

declare var alertify: any;

@Injectable({
    providedIn: 'root'
})
export class JmjHttpService {

    constructor(private http: HttpClient
        , public dialog: MatDialog) {
    }


    /* openDialog(response) {
     this.lastResponse = response;
     const dialogRef = this.dialog.open(NotificationsComponent, {
     height: '350px'
     });

     dialogRef.afterClosed().subscribe(result => {
     console.log(`Dialog result: ${result}`);
     });
     }*/

    /** Sending A POST http request to server
     * this return a Observable
     * Never try to Extract Data*/
    postSubmitNoExtractData(url, requestData, loading = true): Observable<any> {
        return this.postSubmit(url, requestData, loading, false);
    }// End postSubmitNoExtractData


    /**
     * Search via POST request
     * @param url
     * @param requestData
     * @param loading
     * @returns {Observable<any>}
     */
    postSearch(url, requestData, loading = true): Observable<any> {
        return this.postSubmit(url, requestData, loading, false);
    }

    /**
     * POST  WITH A CONFIRMATION
     *
     * @param url
     * @param requestData
     * @param question
     * @param loading
     * @returns {Observable<TResult2|serverResponseData>}
     */
    postConfirmSubmit(url, requestData, question = '', loading = true): Observable<any> {
        question = question ? question : 'Are you sure want to delete ?';
        try {
            if (confirm(question)) {
                return this.postSubmit(url, requestData, loading, true);
            }
        } catch (err) {
            console.log(err);
        }

    }// End postConfirmSubmit

    /**
     * POST REQUEST
     *
     * @param url
     * @param requestData
     * @param loading
     * @param handleMessage
     * @returns {Observable<TResult2|TResult1>}
     */

    postSubmit(url, requestData, loading = true, handleMessage = true): Observable<any> {
        if (loading) {
            this.loading(1);
        }
        return this.http.post(url, requestData)
            .map(res => {
                if (loading) {
                    this.loading(0);
                }
                if (handleMessage) {
                    this.handleServerResponseMessage(res);
                }
                return res;
            });
    }// End postSubmit

    postSubmitObservable(url, requestData, loading = true, handleMessage = true): Observable<any> {
        if (loading) {
            this.loading(1);
        }
        return this.http.post(url, requestData)
            .map(res => {
                if (loading) {
                    this.loading(0);
                }
                if (handleMessage) {
                    this.handleServerResponseMessage(res);
                }
                return res;
            });
    }// End postSubmitObservable

    /**
     *  GET request
     *
     * @param url
     * @param requestData
     * @param loading
     * @param handleMessage
     * @returns {Observable<TResult2|TResult1>}
     */
    getSubmit(url, requestData = {}, loading = true, handleMessage = true): Observable<any> {
        if (loading) {
            this.loading(1);
        }
        url += '?' + this.serialiseObject(requestData);
        return this.http.get(url)
            .map(res => {
                if (loading) {
                    this.loading(0);
                }
                if (handleMessage) {
                    this.handleServerResponseMessage(res);
                }
                return res;
            });
    }

    /*  getSubmitNew(url, requestData = {}, loading = true, handleMessage = true): Observable<any> {
     if (loading) {
     this.loading(1);
     }
     url += '?' + this.serialiseObject(requestData);
     return this.http.get(url)
     .map(res => {
     if (loading) {
     this.loading(0);
     }
     if (handleMessage) {
     this.handleServerResponseMessage(res);
     }
     return res;
     });
     }*/

    /**
     * Make serialised STRING from OBJECT for get Request
     * @param obj
     * @returns {string}
     */
    serialiseObject(obj) {
        let pairs = [];
        for (let prop in obj) {
            if (!obj.hasOwnProperty(prop)) {
                continue;
            }
            pairs.push(prop + '=' + obj[prop]);
        }
        return pairs.join('&');
    }


    /**
     *     * Simple GET
     * @param url
     * @param requestData => Search params
     * @param loading
     * @returns {Observable<any>}
     */
    getSearch(url, requestData = {}, loading = true): Observable<any> {
        return this.getSubmit(url, requestData, loading, false);
    }// End

    /**
     * A default / Simple get request
     * @param url
     * @returns {Observable<any>}
     */
    getDefault(url): Observable<any> {
        return this.getSubmit(url, {}, true, false);
    }// End

    /*  getDefaultNew(url): Observable<any> {
     return this.getSubmitNew(url, {}, true, false);
     }// End
     */
    /**
     *
     * @param url
     * @returns {Observable<Object>}
     */
    getNoExtract(url, loading = true): Observable<any> {
        if (loading) {
            this.loading(1);
        }
        return this.http.get(url)
            .map(res => {
                this.loading(0);
                return res;
            });
    }// End


    extractData(response: serverResponseData) {
        console.log(response);
        if (response.success) {
            alert(response.message);
        }
        return response;
    }

    handleErrorPromise(error: Response | any) {
        console.error(error.message || error);
        return Promise.reject(error.message || error);
    }

    /**
     * Handling Server Response
     * @param response
     * @returns {Array<any>}
     */
    handleServerResponseMessage(response) {
        if (response.success && response.error.length == 0) {
            // alert(response.message);
            alertify.success(response.message);
        } else {
            if (response.error.length === 0) {
                alertify.error(response.message);
            }
            for (let key in response.error) {
                alertify.error(response.error[key]);
            }
            // alert('Error Message => :' + response.message);
        }
    }

    /**
     * Showing Loading Status
     * @param status
     */
    loading(status: number) {
        const targetElement = <HTMLElement> document.querySelector('#loading-wraper');
        if (status == 1) {
            targetElement.style.display = 'block';
        } else {
            targetElement.style.display = 'none';
        }
    }

    addintoBody() {
        // const targetElement = document.querySelector('#loading-wraper');
        // targetElement.style.display = "none"
        // const iframe = document.createElement('iframe');
        // iframe.src = 'https://developer.mozilla.org/en-US/docs/Web/API/Node/appendChild';
        // iframe.width = '650px';
        // iframe.height = '400px';
        // targetElement.appendChild(iframe);
    }


}
