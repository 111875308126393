import {Injectable} from '@angular/core';
import {sessionData} from "../model/common";
import {ServerService} from "./server.service";
import {URL_auth_login} from "../core/url.list";
import {JmjHttpService} from "./jmj-http.service";
import {Observable} from "rxjs/Rx";


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private loggedInStatus = JSON.parse(localStorage.getItem('loggedIn')) || 'false'

    constructor(private server: ServerService
        , public serviceHttp: JmjHttpService) {
    }


    setLoggedIn(sessionData: sessionData) {
        this.loggedInStatus = true
        // console.log('setLoggedIn===>');
        // console.log(sessionData);
        // debugger
        // sessionStorage.setItem('loggedIn', 'true')
        sessionStorage.setItem('access_token', sessionData.access_token.toString())
        sessionStorage.setItem('email', sessionData.email.toString())
        sessionStorage.setItem('role', sessionData.role.toString())
        sessionStorage.setItem('name', sessionData.name.toString())
    }


    cleanLogout() {
        sessionStorage.removeItem('loggedIn')
        sessionStorage.removeItem('access_token')
        sessionStorage.removeItem('email')
        sessionStorage.removeItem('role')
        sessionStorage.removeItem('name')
    }

    get isLoggedIn() {
        if (this.accessToken) {
            return true
        }
        return false
        // return this.http.post<loginData>(this.server.webLink.toString() + 'auth/status', {})
    }

    get accessToken() {
        return sessionStorage.getItem('access_token') || false
    }

    get currentUser() {
        return sessionStorage.getItem('name') || 'No Name';
    }

    // /** Add AUTH token */
    // createAuthorizationHeader() {
    //     this.headers.append('Authorization', 'Bearer ' + btoa(this.accessToken));
    // }

    /** Calling Custom HTTP Service
     * that return a PROMISE
     * Don't try to ExtractData here*/
    authenticate(username, password): Observable<any> {
        return this.serviceHttp.postSubmitNoExtractData(URL_auth_login, {
            email: username,
            password: password
        })
    }
}