import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {JmjHttpService} from "../../services/jmj-http.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    username: string
    password: string
    errorMessage: string

    constructor(private Auth: AuthService,
                private router: Router
        , private serviceHttp: JmjHttpService) {
    }

    ngOnInit() {
        if (this.Auth.isLoggedIn) {
            this.router.navigate([''])
        }
    }

    /** LOGIN */
    loginUser(event) {
        event.preventDefault();
        /** Call service that return a PROMISE */
        this.Auth.authenticate(this.username, this.password)
            .subscribe(response => {
                if (response.success) {
                    // Login Success
                    this.Auth.setLoggedIn(response.data)
                    this.router.navigate([''])
                } else {
                    this.Auth.cleanLogout()
                    // Error message
                    this.errorMessage = response.message;
                }
            })
    }
}
