import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {AuthService} from "../services/auth.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private  auth: AuthService,
                private  router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                // state: RouterStateSnapshot): boolean {
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.auth.isLoggedIn) {
            return true
        } else {
            this.router.navigate(['login']);
            this.auth.cleanLogout
        }
    }
}
